import PublicRoute from './PublicRoute'
import urlRouter from './url'
import FeedbackForm from '../pages/FeedbackForm/FeedbackForm'
import { lazy } from 'react'
import { DesignSystemMainLayout } from '../designSystem/layouts'
import { Routes, Route } from 'react-router-dom'
import { 
  Login, 
  MainApp, 
  Team, 
} from '../pages'
import { 
  DesignSystemButtons, 
  DesignSystemColorGuidance, 
  DesignSystemTypography, 
} from '../designSystem/pages'

const Router = () => {
  // code splitting
  const LastLocation = lazy(() => import('../pages/LastLocation/LastLocation'))
  const Report = lazy(() => import('../pages/Report/Report'))
  const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'))
  const Notification = lazy(() => import('../pages/Notification/Notification'))
  const Invitation = lazy(() => import('../pages/Invitation/Invitation'))

  // attendance
  const AttendanceSetting = lazy(() => import('../pages/AttendanceSetting/AttendanceSetting'))
  const AttendanceMonitoring = lazy(() => import('../pages/AttendanceMonitoring/AttendanceMonitoring'))

  // member
  const MemberMaster = lazy(() => import('../pages/MemberMaster/MemberMaster'))
  const MemberGroup = lazy(() => import('../pages/MemberGroup/MemberGroup'))
  const MemberList = lazy(() => import('../pages/MemberList/MemberList'))
  const MemberSetting = lazy(() => import('../pages/MemberSetting/MemberSetting'))
  const FeedbackMember = lazy(() => import('../pages/FeedbackMember/FeedbackMember'))
  const InviteList = lazy(() => import('../pages/MemberMaster/components/InviteList/InviteList'))

  // task
  const MasterTask = lazy(() => import('../pages/MasterTask/MasterTask'))
  const TaskType = lazy(() => import('../pages/TaskType/TaskType'))
  const TaskSetting = lazy(() => import('../pages/TaskSetting/TaskSetting'))

  // configuration
  const Lookup = lazy(() => import('../pages/Lookup/Lookup'))

  // item
  const DashboardItem = lazy(() => import('../pages/Item/DashboardItem/DashboardItem'))
  const AddItem = lazy(() => import('../pages/AddItem/AddItem'))
  const ItemList = lazy(() => import('../pages/ItemList/ItemList'))

  // cash advanced
  const CashAdvancedMaster = lazy(() => import('../pages/CashAdvancedMaster/CashAdvancedMaster'))
  const CashAdvancedTransaction = lazy(() => import('../pages/CashAdvancedTransaction/CashAdvancedTransaction'))

  // Customer
  const CustomerSetting = lazy(() => import('../pages/CustomerSetting/CustomerSetting'))
  const CustomerCreate = lazy(() => import('../pages/CustomerCreate/CustomerCreate'))
  const MasterCustomer = lazy(() => import('../pages/MasterCustomer/MasterCustomer'))
  const CustomerAnalisis = lazy(() => import('../pages/CustomerAnalisis/CustomerAnalisis'))
  
  //Task
  const TaskMonitoring = lazy(()=> import('../pages/TaskMonitoring/TaskMonitoring'))
  const TaskAnalytics = lazy(()=> import('../pages/TaskAnalytics/TaskAnalytics'))
  const TaskTabs = lazy(()=>import('../pages/TaskMonitoring/actions/Tabs'))
  const CreateTask = lazy (()=>import('../pages/CreateTask/CreateTask'))
  const EditTask = lazy (()=> import('../pages/EditTask/EditTask') )

  // builder
  const DashboardBuilder = lazy(() => import('../pages/DashboardBuilder/DashboardBuilder'))
  const BuilderReport = lazy(() => import('../pages/BuilderReport/BuilderReport'))

  // MEMBER JOURNEY
  const MemberJourney = lazy(() => import('../pages/MemberJourney/MemberJourney'))
  const MemberJourneyDetail = lazy(() => import('../pages/MemberJourneyDetail/MemberJourneyDetail'))

  // const FeedbackForm = lazy(() => import('../pages/FeedbackForm/FeedbackForm'))

  // PERSONAL DASHBAORD
  const PersonalDashboard = lazy(() => import('../pages/PersonalDashboard/PersonalDashboard'))
  const PersonalDashboardPco = lazy(() => import('../pages/PersonalDashboardPco/PersonalDashboardPco'))

  // LEAVE
  const LeaveApproval = lazy(() => import('../pages/LeaveApproval/LeaveApproval'))
  const LeaveSetting = lazy(() => import('../pages/LeaveSetting/LeaveSetting'))

  // TEAM CONFIGURATION
  const TeamConfiguration = lazy(() => import('../pages/TeamConfiguration/TeamConfiguration'))

  /**
   * INVOICE
   */
  const InvoiceDashboard = lazy(() => import('../pages/InvoiceDashboard/InvoiceDashboard'))
  const InvoiceMaster = lazy(() => import('../pages/InvoiceMaster/InvoiceMaster'))
  const InvoiceCreate = lazy(() => import('../pages/InvoiceCreate/InvoiceCreate'))
  const InvoiceDetail = lazy(() => import('../pages/InvoiceDetail/InvoiceDetail'))
  const InvoiceSetting = lazy(() => import('../pages/InvoiceSetting/InvoiceSetting'))

  /**
   * Management Ticket
   */
  const ManagementTicket = lazy(() => import('../pages/ManagementTicket/ManagementTicket'))
  const TicketDetail = lazy(() => import('../pages/TicketDetail/TicketDetail'))
  const ManagementTicketTab = lazy(() => import('../pages/ManagementTicket/Action/TicketTabs'))
  const SettingTicket = lazy(() => import('../pages/TicketSetting/TicketSetting'))

  /**
   * Leads
   */
  const LeadsMaster = lazy(() => import('../pages/Leads/LeadsMaster/LeadsMaster'))
  const LeadsDashboard = lazy(() => import('../pages/Leads/LeadsDashboard/LeadsDashboard'))
  const LeadsActivity = lazy(() => import('../pages/Leads/LeadsActivity/LeadsActivity'))
  const LeadsActivityResult = lazy(() => import('../pages/Leads/LeadDetail/components/TabSummary/DetailActivity/DetailActivityResult'))
  const LeadsSetting = lazy(() => import('../pages/Leads/LeadsSetting/LeadsSetting'))
  const LeadsCreate = lazy(() => import('../pages/Leads/LeadsCreate/LeadsCreate'))
  const LeadsImport = lazy(() => import('../pages/Leads/LeadsImport/LeadImport'))
  const LeadsDetail = lazy(() => import('../pages/Leads/LeadDetail/LeadDetail'))
  const LeadsEdit = lazy(() => import('../pages/Leads/LeadEdit/LeadEdit'))

  /**
   * Bulletin
   */
  const Bulletin = lazy(() => import('../pages/Bulletin/Bulletin'))
  const FormBulletin = lazy(() => import('../pages/Bulletin/components/FormBulletin/FormBulletin'))

  /**
   * Researches
   */
  const MapPolygon = lazy(() => import('../pages/Researches/MapPolygon/MapPolygon'))
  const PdfEditor = lazy(() => import('../pages/Researches/PdfEditor/PdfEditor'))
  const CalendarView = lazy(() => import('../pages/Researches/CalendarView/CalendarView'))

  /**
   * Inventory
   */
  const InventoryAddItem = lazy(() => import('../pages/Inventory/AddItem/AddItem'))
  const InventoryMasterItem = lazy(() => import('../pages/Inventory/MasterItem/MasterItem'))
  const InventoryMasterCategory = lazy(() => import('../pages/Inventory/MasterCategory/MasterCategory'))
  const InventoryStockHistory = lazy(() => import('../pages/Inventory/StockHistory/StockHistory'))
  const InventoryUsage = lazy(() => import('../pages/Inventory/InventoryUsage/InventoryUsage'))
  const InventoryDashboard = lazy(() => import('../pages/Inventory/DashboardInventory/DashboardInventory'))
  const InventoryInventory = lazy(() => import('../pages/Inventory/Inventory/Inventory'))

  /**
   * Settlement
   */
  const SettlementSettlement = lazy(() => import('../pages/Settlement/Settlement/Settlement'))

  /**
   * POS
   */
  const PosDashboard = lazy(() => import('../pages/Pos/Dashboard/Dashboard'))
  const PosMasterMenu = lazy(() => import('../pages/Pos/MasterMenu/MasterMenu'))
  const PosCommission = lazy(() => import('../pages/Pos/Commission/Commission'))
  const PosTransaction = lazy(() => import('../pages/Pos/Transaction/Transaction'))

  /**
   * Standalone
   * Master Outlet
   */
  const MasterOutlet = lazy(() => import('../pages/MasterOutlet/MasterOutlet'))

  /**
   * Standalone
   * Master Product
   */
  const MasterProduct = lazy(() => import('../pages/MasterProduct/MasterProduct'))

  /**
   * Standalone
   * Master Ingredient
   */
  const MasterIngredient = lazy(() => import('../pages/MasterIngredient/MasterIngredient'))

  /**
   * Standalone
   * Master Recipe
   */
  const MasterRecipe = lazy(() => import('../pages/MasterRecipe/MasterRecipe'))

  /**
   * Standalone
   * Task Calendar View
   */
  const TaskCalendarView = lazy(() => import('../pages/TaskCalendarView/TaskCalendarView'))

  const isModuleItemEnable = false
  const isLeadsEnable = true
  const isPosEnable = true
  const isInvoiceEnable = false
  const isInventoryEnable = true
  const isSettlementEnable = true
  const isMasterOutletEnable = true
  const isMasterProductEnable = true
  const isMasterIngredientEnable = true
  const isMasterRecipeEnable = true
  const isTaskCalendarViewEnable = true

  return (
    <Routes>
      <Route path={urlRouter.home} element={<MainApp />}>
        <Route path={urlRouter.dashboard} element={<Dashboard />} />
        <Route path={urlRouter.lastLocation} element={<LastLocation />} />
        <Route path={urlRouter.report} element={<Report />} />
        <Route path={urlRouter.team} element={<Team />} />
        <Route path={urlRouter.notification} element={<Notification />} />
        <Route path={urlRouter.invitation} element={<Invitation />} />

        {/* customer */}
        <Route path={urlRouter.customerSetting} element={<CustomerSetting/>} />
        <Route path={urlRouter.CUSTOMER_MASTER} element={<MasterCustomer/>} />
        <Route path={urlRouter.customerAnalisis} element={<CustomerAnalisis/>} />
        <Route path={urlRouter.CUSTOMER_CREATE} element={<CustomerCreate/>} />
        <Route path={urlRouter.CUSTOMER_EDIT} element={<CustomerCreate/>} />

        {/* task */}
        <Route path={urlRouter.taskMaster} element={<MasterTask />} />
        <Route path={urlRouter.createTask} element={<CreateTask/>}/>
        <Route path={urlRouter.taskType} element={<TaskType />} />
        <Route path={urlRouter.taskAnalytics} element={<TaskAnalytics />} />
        <Route path={urlRouter.taskMonitoring} element={<TaskMonitoring />} />
        <Route path={urlRouter.taskMonitoringDetailResult} element={<TaskTabs />} />
        <Route path={urlRouter.taskSetting} element={<TaskSetting />} />
        <Route path={urlRouter.editTask} element={<EditTask/>}/>

        {/* item */}
        {isModuleItemEnable
          ? <>
              <Route path={urlRouter.itemDashboard} element={<DashboardItem/>} />
              <Route path={urlRouter.itemList} element={<ItemList />} />
              <Route path={urlRouter.itemCreate} element={<AddItem />} />
            </>
          : null
        }

        {/* cash advanced */}
        <Route path={urlRouter.cashAdvance} element={<CashAdvancedMaster />} />
        <Route path={urlRouter.cashAdvanceTransaction} element={<CashAdvancedTransaction />} />

        {/* member */}
        <Route path={urlRouter.memberMaster} element={<MemberMaster />} />
        <Route path={urlRouter.memberGroup} element={<MemberGroup />} />
        <Route path={urlRouter.memberPrivilage} element={<MemberList />} />
        <Route path={urlRouter.memberSetting} element={<MemberSetting />} />
        <Route path={urlRouter.memberFeedback} element={<FeedbackMember />} />
        <Route path={urlRouter.memberInviteList} element={<InviteList />} />

        {/* attendance */}
        <Route path={urlRouter.attendaceMonitoring} element={<AttendanceMonitoring />} />
        <Route path={urlRouter.attendaceSetting} element={<AttendanceSetting />} />

        {/* configuration */}
        <Route path={urlRouter.lookup} element={<Lookup />} />

        {/* builder */}
        <Route path={urlRouter.builderReport} element={<BuilderReport />} />
        <Route path={urlRouter.builderDashboard} element={<DashboardBuilder />} />
        <Route path={urlRouter.builderReport} element={<></>} />

        {/* MEMBER JOURNEY */}
        <Route path={urlRouter.memberJourney} element={<MemberJourney />} />
        <Route path={urlRouter.memberJourneyDetail} element={<MemberJourneyDetail />} />

        {/* PERSONAL DASHBOARD */}
        <Route path={urlRouter.PERSONAL_DASHBOARD} element={<PersonalDashboard />} />
        <Route path={urlRouter.PERSONAL_DASHBOARD_PCO} element={<PersonalDashboardPco />} />

        {/* LEAVE */}
        <Route path={urlRouter.LEAVE_APPROVAL} element={<LeaveApproval />} />
        <Route path={urlRouter.LEAVE_SETTING} element={<LeaveSetting />} />

        /**
        TEAM CONFIGURATION
         */
        <Route path={urlRouter.TEAM_CONFIGURATION} element={<TeamConfiguration />} />

        /**
        INVOICE
         */
        {isInvoiceEnable
          ? <>
              <Route path={urlRouter.INVOICE_DASHBOARD} element={<InvoiceDashboard />} />
              <Route path={urlRouter.INVOICE_MASTER} element={<InvoiceMaster />} />
              <Route path={urlRouter.INVOICE_CREATE} element={<InvoiceCreate />} />
              <Route path={urlRouter.INVOICE_DETAIL} element={<InvoiceDetail />} />
              <Route path={urlRouter.INVOICE_SETTING} element={<InvoiceSetting />} /> 
            </>
          : null
        }

        /**
         * Management Ticket
         */
        <Route path={urlRouter.MANAGEMENT_TICKET} element={<ManagementTicket />} />
        <Route path={urlRouter.MANAGEMENT_TICKET_DETAIL} element={<TicketDetail />} />
        <Route path={urlRouter.MANAGEMENT_TICKET_DETAIL} element={<ManagementTicketTab />} />
        <Route path={urlRouter.MANAGEMENT_TICKET_SETTING} element={<SettingTicket />} />

        /** 
         * Leads
         */
        {isLeadsEnable
          ? <>
              <Route path={urlRouter.LEADS_MASTER} element={<LeadsMaster />} />
              <Route path={urlRouter.LEADS_DASHBOARD} element={<LeadsDashboard />} />
              <Route path={urlRouter.LEADS_ACTIVITY} element={<LeadsActivity />} />
              <Route path={urlRouter.LEADS_SETTING} element={<LeadsSetting />} />
              <Route path={urlRouter.LEADS_CREATE} element={<LeadsCreate />} />
              <Route path={urlRouter.LEADS_IMPORT} element={<LeadsImport />} />
              <Route path={urlRouter.LEADS_DETAIL} element={<LeadsDetail />} />
              <Route path={urlRouter.LEADS_EDIT} element={<LeadsEdit />} />
              <Route path={urlRouter.LEADS_DETAIL_RESULT  } element={<LeadsActivityResult />} />
            </>
          : null
        }

        /**
         * Bulletin
         */
        <Route path={urlRouter.BULLETIN} element={<Bulletin />} />
        <Route path={urlRouter.BULLETIN_CREATE} element={<FormBulletin />} />
        <Route path={urlRouter.BULLETIN_EDIT} element={<FormBulletin />} />

        /**
         * Inventory
         */
        {isInventoryEnable
          ? <>
              <Route path={urlRouter.INVENTORY_DASHBOARD} element={<InventoryDashboard />} />
              <Route path={urlRouter.INVENTORY_MASTER_ITEM} element={<InventoryMasterItem />} />
              <Route path={urlRouter.INVENTORY_MASTER_CATEGORY} element={<InventoryMasterCategory />} />
              <Route path={urlRouter.INVENTORY_USAGE} element={<InventoryUsage />} />
              <Route path={urlRouter.INVENTORY_STOCK_HISTORY} element={<InventoryStockHistory />} />
              <Route path={urlRouter.INVENTORY_ADD_ITEM} element={<InventoryAddItem />} />
              <Route path={urlRouter.INVENTORY_INVENTORY} element={<InventoryInventory />} />
            </>
          : null
        }

        /**
         * Settlement
         */
        {isSettlementEnable
          ? <Route path={urlRouter.SETTLEMENT_SETTLEMENT} element={<SettlementSettlement />} />
          : null
        }

        /**
         * POS
         */
        {isPosEnable
          ? <>
              <Route path={urlRouter.POS_DASHBOARD} element={<PosDashboard />} />
              <Route path={urlRouter.POS_MASTER_MENU} element={<PosMasterMenu />} />
              <Route path={urlRouter.POS_COMMISSION} element={<PosCommission />} />
              <Route path={urlRouter.POS_TRANSACTION} element={<PosTransaction />} />
            </>
          : null
        }

        /**
         * Master Outlet
         */
        {isMasterOutletEnable
          ? <Route path={urlRouter.MASTER_OUTLET} element={<MasterOutlet />} />
          : null
        }

        /**
         * Master Product
         */
        {isMasterProductEnable
          ? <Route path={urlRouter.MASTER_PRODUCT} element={<MasterProduct />} />
          : null
        }

        /**
         * Master Ingredient
         */
        {isMasterIngredientEnable
          ? <Route path={urlRouter.MASTER_INGREDIENT} element={<MasterIngredient />} />
          : null
        }

/**
         * Master Ingredient
         */
        {isMasterRecipeEnable
          ? <Route path={urlRouter.MASTER_RECIPE} element={<MasterRecipe />} />
          : null
        }

        /**
         * Task Calendar View
         */
        {isTaskCalendarViewEnable
          ? <Route path={urlRouter.TASK_CALENDAR_VIEW} element={<TaskCalendarView />} />
          : null
        }
        
        /**
         * Researches
         */
        <Route path={urlRouter.RESEARCH_MAP_POLYGON} element={<MapPolygon />} />
        <Route path={urlRouter.RESEARCH_PDF_EDITOR} element={<PdfEditor />} />
        <Route path={urlRouter.RESEARCH_CALENDAR_VIEW} element={<CalendarView />} />
      </Route>

      <Route path={urlRouter.login} element={<PublicRoute />}>
        <Route path={urlRouter.login} element={<Login />} />
      </Route>

      <Route path={urlRouter.DESIGN_SYSTEM} element={<DesignSystemMainLayout />}>
        <Route path={urlRouter.DESIGN_SYSTEM_TYPOGRAPHY} element={<DesignSystemTypography />} />
        <Route path={urlRouter.DESIGN_SYSTEM_COLOR_GUIDANCE} element={<DesignSystemColorGuidance />} />
        <Route path={urlRouter.DESIGN_SYSTEM_BUTTONS} element={<DesignSystemButtons />} />
      </Route>

      <Route path={urlRouter.feedbackForm} element={<FeedbackForm />} />
    </Routes>
  )
}

export default Router