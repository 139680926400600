const urlRouter = {
  home: '/',
  login: '/login',
  team: '/page-team',
  invitation: '/page-invitation',
  dashboard: '/',
  lastLocation: '/last-location',
  customer: '/customer',
  customerSetting: '/customer-setting',
  customerAnalisis: '/customer-analisis',
  lookup: '/lookup',
  report: '/report',
  checkFile: '/file/:doctype/:category/:id/:name/:page',
  notificationDetail: '/notifications-detail',
  notification: '/notification',

  // task
  taskMaster: '/task',
  taskAnalytics: '/task-analytics',
  createTask: '/task/create',
  editTask: '/task/edit/:id',
  taskType: '/task-type',
  taskMonitoring: '/task-monitoring',
  taskMonitoringDetailResult: '/task-monitoring/:id/:slug-task',

  taskSetting: '/task-setting',

  // item
  itemDashboard: '/item/dashboard',
  itemList: '/item-list',
  itemCreate: '/add-item',
  itemEdit: 'edit-item/:id/edit',

  // member
  memberMaster: '/member',
  memberPrivilage: '/member-privilage',
  memberGroup: '/group-member',
  memberSetting: '/member-setting',
  memberFeedback: '/member-feedback',
  memberInviteList: '/invite-list',

  // attendance
  attendaceMonitoring: '/attendance/attendance-monitoring',
  attendaceSetting: '/attendance/attendance-settings',

  // cash advance
  cashAdvance: '/cash-advance',
  cashAdvanceTransaction: '/cash-advanced/transaction',

  // task discussion
  taskDiscussion: '/task-discussion',
  taskDiscussionDrawer: '/task-discussion/drawer',

  // builder
  builderDashboard: '/builder/dashboard-builder',
  builderReport: '/builder/report-builder',
  builderReportCreate: '/builder/report-builder/create',
  builderReportEdit: '/builder/report-builder/:id/:name/edit',

  // development
  devButton: '/development/button',
  devTypography: '/development/typography',

  // item
  dashboardItem: '/item/dashboard',

  // DESIGN SYSTEM
  DESIGN_SYSTEM: '/design-system',
  DESIGN_SYSTEM_TYPOGRAPHY: '/design-system/typography',
  DESIGN_SYSTEM_COLOR_GUIDANCE: '/design-system/color-guidance',
  DESIGN_SYSTEM_BUTTONS: '/design-system/buttons',

  // MEMBER JOURNEY
  memberJourney: '/member-journey',
  memberJourneyDetail: '/member-journey-detail',

  feedbackForm: '/feedback-form',

  // PERSONAL DASHBOARD
  PERSONAL_DASHBOARD: 'personal-dashboard',
  PERSONAL_DASHBOARD_PCO: 'personal-dashboard-pco',

  // CUSTOMER
  CUSTOMER_MASTER: '/master-customer',
  CUSTOMER_CREATE: '/customer/create',
  CUSTOMER_EDIT: '/customer/edit/:customerId',

  // LEAVE
  LEAVE_APPROVAL: '/leave/approval',
  LEAVE_SETTING: '/leave/setting',

  // TEAM
  TEAM_CONFIGURATION: '/team',

  // INVOICE
  INVOICE_DASHBOARD: '/invoice',
  INVOICE_MASTER: '/invoice/master',
  INVOICE_CREATE: '/invoice/create',
  INVOICE_DETAIL: '/invoice/:invoiceId',
  INVOICE_SETTING: '/invoice/setting',

  // PAYMENT
  PAYMENT_RECEIVE: '/payment-receive',
  PAYMENT_RECEIVE_DETAIL: '/payment-receive/detail',
  PAYMENT_RECEIVE_EDIT: '/payment-receive/edit',

  // MANAGEMENT TICKET
  MANAGEMENT_TICKET: '/management-ticket',
  MANAGEMENT_TICKET_DETAIL: '/management-ticket/:ticketId',
  MANAGEMENT_TICKET_SETTING: '/setting-ticket',

  // LEADS
  LEADS_MASTER: '/leads/master',
  LEADS_DASHBOARD: '/leads/dashboard',
  LEADS_ACTIVITY: '/leads/activity',
  LEADS_SETTING: '/leads/setting',
  LEADS_CREATE: '/leads/create',
  LEADS_IMPORT: '/leads/import',
  LEADS_DETAIL: '/leads/detail/:id',
  LEADS_EDIT: '/leads/edit/:id',
  LEADS_DETAIL_RESULT: '/leads/detail/result',

  //BULLETIN
  BULLETIN: '/bulletin',
  BULLETIN_CREATE: '/bulletin/create',
  BULLETIN_EDIT: '/bulletin/edit/:id',

  // RESEARCHES
  RESEARCH_MAP_POLYGON: '/research/map-polygon',
  RESEARCH_PDF_EDITOR: '/research/pdf-editor',
  RESEARCH_CALENDAR_VIEW: '/research/calendar-view',

  // INVENTORY
  INVENTORY_DASHBOARD: '/inventory/dashboard',
  INVENTORY_MASTER_ITEM: '/inventory/master-item',
  INVENTORY_USAGE: '/inventory/usage',
  INVENTORY_STOCK_HISTORY: '/inventory/stock-history',
  INVENTORY_ADD_ITEM: '/inventory/add-item',
  INVENTORY_INVENTORY: '/inventory/inventory',
  INVENTORY_MASTER_CATEGORY: '/inventory/master-category',

  // SETTLEMENT
  SETTLEMENT_SETTLEMENT: '/settlement/settlement',

  // POS
  POS_MASTER_MENU: '/pos/master-menu',
  POS_DASHBOARD: '/pos/dashboard',
  POS_COMMISSION: '/pos/commission',
  POS_TRANSACTION: '/pos/transaction',

  // MASTER OUTLET
  MASTER_OUTLET: '/master-outlet',

  // MASTER PRODUCT
  MASTER_PRODUCT: '/master-product',

  // MASTER INGREDIENT
  MASTER_INGREDIENT: '/master-ingredient',

  // MASTER RECIPE
  MASTER_RECIPE: '/master-recipe',

  // TASK CALENDAR VIEW
  TASK_CALENDAR_VIEW: '/task-calendar-view',
};

export default urlRouter;